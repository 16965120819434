import loadable from "@loadable/component"
import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "twin.macro"

const LoadableCalendar = loadable(
  () => import("../components/LoadableCalendar"),
  {
    fallback: <p>Loading the latest events just for you...</p>,
  }
)

export default function CalendarPage() {
  return (
    <Layout>
      <SEO title={"Calendar"} />
      <div tw="flex-1 w-full px-4 py-8 md:px-20 mx-auto md:px-8 max-w-screen-xl">
        {/* <p tw="text-center">Our calendar is coming soon!</p> */}
        <h1
          tw={
            "text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10"
          }
        >
          Calendar
        </h1>
        <p tw={"mt-3 mb-6"}>
          You can add our calendar to your own google calendar by clicking{" "}
          <a
            tw={"underline text-blue-600 "}
            href={
              "https://calendar.google.com/calendar/u/0?cid=aGxjdXVwdGdzdHUzNnVxcjV1NWVmbGdqbmdAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ"
            }
            target={"_blank"}
            rel={"noopener noreferrer"}
          >
            here
          </a>
        </p>
        <div tw={"w-full"}>
          <LoadableCalendar />
        </div>
      </div>
    </Layout>
  )
}
